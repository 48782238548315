/**
 * This code was generated by v0 by Vercel.
 * @see https://v0.dev/t/JTO15OV53eA
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */

/** Add fonts into your Next.js project:

import { Inter } from 'next/font/google'

inter({
  subsets: ['latin'],
  display: 'swap',
})

To read more about using these font, please visit the Next.js documentation:
- App Directory: https://nextjs.org/docs/app/building-your-application/optimizing/fonts
- Pages Directory: https://nextjs.org/docs/pages/building-your-application/optimizing/fonts
**/
"use client";

import { Button } from "@/components/ui/button";
import { BugIcon } from "lucide-react";
import Link from "next/link";

export function ErrorComponent() {
  return (
    <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8 dark:bg-gray-900">
      <div className="text-center">
        <div className="mb-8 flex items-center justify-center">
          <BugIcon className="h-12 w-12 text-gray-900 dark:text-gray-50" />
        </div>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl dark:text-gray-50">
          Oops! Something went wrong.
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600 dark:text-gray-400">
          {
            "Sorry, something went wrong. Please check the URL or go back to the homepage."
          }
        </p>
        <div className="mt-10 flex flex-col sm:flex-row items-center justify-center gap-4">
          <Link href="/">
            <Button>Go back home</Button>
          </Link>
          <Link href="https://status.revues.ca">
            <Button variant="outline">Check our uptime status</Button>
          </Link>
        </div>
      </div>
    </main>
  );
}
